import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Lead } from "./typography"
import { Link } from "gatsby"

const SubjectList = ({ books, catalogue }) => {
  let areas = { base: [], tecnica: [] }
  const subjects = books.map(book => book.subject)
  const unique = subjects.filter(
    (v, i, a) => a.findIndex(t => t.originalId === v.originalId) === i
  )
  unique.map(subject => areas[subject.area.name.toLowerCase()].push(subject))

  areas.base.sort((a, b) => a.position - b.position)
  areas.tecnica.sort((a, b) => a.position - b.position)

  // console.log(unique, areas)
  return (
    <Wrapper>
      <List>
        <li>
          <Link to={`/${catalogue.slug}/`} activeClassName="active">
            Tutte le materie
          </Link>
        </li>
      </List>
      <Lead>Area Base</Lead>
      <List>
        {areas["base"].map(subject => (
          <li key={subject.originalId}>
            <Link
              to={`/${catalogue.slug}/${subject.slug}/`}
              activeClassName="active"
            >
              {subject.name}
            </Link>
          </li>
        ))}
      </List>
      <Lead>Area Tecnica</Lead>
      <List>
        {areas["tecnica"].map(subject => (
          <li key={subject.originalId}>
            <Link
              to={`/${catalogue.slug}/${subject.slug}/`}
              activeClassName="active"
            >
              {subject.name}
            </Link>
          </li>
        ))}
      </List>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
const List = styled.ul`
  margin: 0;
  margin-bottom: 4rem;
  list-style: none;
  & > li {
    margin: 0;
    a {
      text-decoration: none;
      display: block;
      color: ${DesignTokens.colors.secondary[300]};
      padding-top: 1.25rem;
      padding-bottom: 0.75rem;
      border-bottom: 1px solid #ccc;
      line-height: 1.1;
      &:hover,
      &.active {
        border-bottom: 1px solid ${DesignTokens.colors.primary[500]};
        color: ${DesignTokens.colors.primary[500]};
      }
      &.active {
        font-weight: bold;
      }
    }
  }
`

export default SubjectList
