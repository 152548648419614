import { useStaticQuery, graphql } from "gatsby"

export const useBooks = () => {
  const menu = useStaticQuery(graphql`
    query ReferenceQuery {
      allDatoCmsBook(
        sort: { fields: [publishedAt, title], order: [DESC, ASC] }
      ) {
        nodes {
          title
          slug
          originalId
          cover {
            fluid(maxWidth: 400) {
              ...GatsbyDatoCmsFluid
            }
          }
          authors {
            originalId
            name
          }
          catalogue {
            name
            originalId
          }
          digitalDownload
          newEdition {
            title
            slug
            originalId
          }
          subject {
            name
            slug
            originalId
            position
            area {
              name
            }
          }
          price
        }
      }
    }
  `)
  return menu.allDatoCmsBook.nodes
}
