import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { useBooks } from "../hooks/useBooks"
import BookThumb from "../components/bookThumb"
import { Display, Lead } from "../components/typography"
import SubjectList from "../components/subjectList"
import PageTitle from "../components/pageTitle"
import InnerSearchForm from "../components/innerSearchForm"
import { Box } from "theme-ui"

const SubjectPage = ({ data: { subject, catalogue }, pageContext }) => {
  function isSameCatalogue(book) {
    const sameCatalogue = book => book.originalId === catalogue.originalId
    return book.catalogue.some(sameCatalogue)
  }
  function isSameSubject(book) {
    return book.subject.originalId === subject.originalId
  }
  const books = useBooks().filter(book => isSameCatalogue(book))
  const subjectBooks = books.filter(book => isSameSubject(book))

  // console.log(subject)
  return (
    <Layout>
      <SEO title={subject.name} />
      <Box sx={{ mt: [3, 4, 4] }}>
        <InnerSearchForm />
      </Box>
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <PageTitle>
                <Display as="h2">{catalogue.name}</Display>
                <Lead as="h1">{subject.name}</Lead>
              </PageTitle>
            </Col>
          </Row>
          <Row gutterWidth={64}>
            <Col md={3}>
              <SubjectList books={books} catalogue={catalogue} />
            </Col>
            <Col md={9}>
              <Row>
                {subjectBooks.map(book => (
                  <Col md={4} key={book.originalId}>
                    <BookThumb book={book} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default SubjectPage

export const query = graphql`
  query SubjectQuery($originalId: String!, $catalogueId: String!) {
    subject: datoCmsSubject(originalId: { eq: $originalId }) {
      name
      slug
      originalId
    }
    catalogue: datoCmsCatalogue(originalId: { eq: $catalogueId }) {
      name
      slug
      originalId
    }
  }
`
